const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#002955',
  textColor: '#303538', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#E2E4E4', // 6
  inactiveIcon: '#E2E4E4', // 7
  primary: '#005182', // 8
  primaryHover: '#002955', // 9
  primaryGradient: 'linear-gradient(-213deg, #005182 30%, #207db2 90%)',
  primaryGradientLight: 'linear-gradient(to top,#00518217 30%,#207db229 90%)',
  primaryGradientLightInverted:
    'linear-gradient(to bottom,#00518217 30%,#207db229 90%)',
  secondary: '#343a40', // 10
  secondaryHover: '#ffffff', // 11
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
};

export default colors;
