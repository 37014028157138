import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import IntroSection from 'common/src/components/IntroSection';
import AppSlider from 'common/src/components/AppSlider';
import { IpadCarousel, QuoteText } from 'common/src/data/SaasTwo/index';

import Sections from './Sections';
import InfoSectionWrapper, { TextWrapper } from './info.style';

const InfoSection = ({
  row,
  sectionWrapper,
  generalCol,
  imageArea,
  fadeWrapper,
  lineWrapper,
  specialIntro,
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="solutions">
      <Container>
        <InfoSectionWrapper>
          <IntroSection
            title="SOLUTIONS"
            subTitle="Tool catered to all stakeholders in the ecosystem"
          />

          <TextWrapper>
            <Text
              {...lineWrapper}
              className="text"
              content={`" ${QuoteText} "`}
            />
          </TextWrapper>
          <Box {...row}>
            <Box {...generalCol} {...imageArea}>
              <Fade left {...fadeWrapper}>
                <AppSlider
                  options={IpadCarousel}
                  // title="We’re here to simplify data and accessibility by drawing one global line of ownership for all stakeholders."
                />
              </Fade>
            </Box>
            <Sections generalCol={generalCol} className="section_are" />
          </Box>
        </InfoSectionWrapper>
      </Container>
    </Box>
  );
};

InfoSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  generalCol: PropTypes.object,
  fadeWrapper: PropTypes.object,
  lineWrapper: PropTypes.object,
  specialIntro: PropTypes.object,
  // title: PropTypes.object,
  // description: PropTypes.object,
  // subDescription: PropTypes.object,
  // button: PropTypes.object,
  // textArea: PropTypes.object,
  imageArea: PropTypes.object,
  // imageWrapper: PropTypes.object,
  // imageWrapperOne: PropTypes.object,
  // imageOne: PropTypes.object,
  // imageWrapperTwo: PropTypes.object,
  textAreaRow: PropTypes.object,
};

InfoSection.defaultProps = {
  sectionWrapper: {
    pt: ['40px', '40px', '40px', '40px'],
    pb: ['40px', '40px', '40px', '40px'],
  },
  specialIntro: {
    mb: '20px',
  },
  row: {
    display: 'flex',
    flexGtow: 1,
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    ml: ['-30px', '-30px', '-30px', '-30px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-30px', '-45px'],
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  generalCol: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pr: '18px',
    pl: '18px',
  },
  // textArea: {
  //   width: ['100%', '100%', '50%', '50%', '42%'],
  // },
  imageArea: {
    // width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    justifyContent: 'center',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  fadeWrapper: {
    display: 'contents',
  },
  lineWrapper: {
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    mt: '-20px',
    mb: ['30px', '40px'],
  },
  // imageWrapperOne: {
  //   // mr: ['-250px', '-250px', '-200px', '-250px', '-400px'],
  //   mb: '25px',
  // },
  // imageOne: {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  // imageWrapperTwo: {
  //   alignSelf: 'flex-end',
  //   mb: '-50px',
  // },
  // title: {
  //   fontSize: ['26px', '32px', '36px', '40px', '48px'],
  //   fontWeight: '400',
  //   color: '#0f2137',
  //   letterSpacing: '-0.025em',
  //   mb: '20px',
  //   lineHeight: '1.25',
  // },
  // description: {
  //   fontSize: ['15px', '15px', '15px', '16px', '16px'],
  //   color: '#343d48cc',
  //   lineHeight: '2',
  // },
  // subDescription: {
  //   fontSize: ['15px', '15px', '15px', '16px', '16px'],
  //   color: '#343d48cc',
  //   lineHeight: '2',
  //   mb: '33px',
  // },
  // button: {
  //   type: 'button',
  //   fontSize: '14px',
  //   fontWeight: '600',
  //   borderRadius: '4px',
  //   pl: '22px',
  //   pr: '22px',
  //   colors: 'secondaryWithBg',
  // },
};

export default InfoSection;
