import styled from 'styled-components';
import FooterImage from 'common/src/assets/image/saasTwo/footer-bg.png';
import { themeGet } from 'styled-system';

// set in footerWRapper
// @media (min-width: 576px) {
//   &:before {
//     content: '';
//     position: absolute;
//     width: 104%;
//     padding-bottom: 104%;
//     border-top-right-radius: 11%;
//     top: 18%;
//     left: 0;
//     pointer-events: none;
//     background-color: #fff;
//     transform: rotate(-6deg);
//     @media (max-width: 767px) {
//       padding-bottom: 150%;
//     }
//   }
// }

const FooterWrapper = styled.footer`
  position: relative;
  overflow: hidden;
  background-color: #343a40;
  padding: 0px 20px 0px;
  color: #fff;
  @media (max-width: 990px) {
    padding: 30px 0 30px;
  }

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 10px;
    }
    @media (max-width: 767px) {
      padding-bottom: 10px;
    }
  }
  .contactForm {
    * {
      border-radius: 6px;
    }
    label {
      display: block;
      color: ${themeGet('colors.labelColor', '#767676')};
      font-size: ${themeGet('fontSizes.4', '16')}px;
      font-weight: ${themeGet('fontWeights.4', '500')};
      margin-bottom: ${themeGet('space.3', '10')}px;
      transition: 0.2s ease all;
    }
    textarea,
    input {
      font-size: 16px;
      padding: 11px;
      display: block;
      width: 100%;
      color: ${themeGet('colors.textColor', '#484848')};
      box-shadow: none;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid ${themeGet('colors.inactiveIcon', '#ebebeb')};
      transition: border-color 0.2s ease;
      &:focus {
        outline: none;
        border-color: ${themeGet('colors.primary', '#028489')};
      }
    }
    textarea {
      min-height: 150px;
      border-color: inherit
      width: 100%
    }
  }

  .copyrightClass {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .copyrightMenu {
      @media (max-width: 1024px) {
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: left;
        align-items: left;
        margin-left: 0;
      }
      @media (max-width: 767px) {
        justify-content: left;
        align-items: left;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .copyrightText {
      @media (max-width: 1100px) {
        margin-left: 0;
      }
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
  }
`;

const Newsletter = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  background: #fff;
  border-radius: 6px;
  height: 50px;
  margin: 10px 10px 10px 0px;
  @media (max-width: 990px) {
    width: 100%;
    margin: 10px 0px 10px;
  }

  .reusecore__input {
    flex-grow: 1;
    * {
      height: 100%;
    }
    input {
      border: none;
      padding-left: 22px;
      color: #302b4e;
      &:focus {
        outline: none;
      }
    }
  }
  .reusecore__button {
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }
`;

const FooterNav = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: auto;
  @media (max-width: 575px) {
    margin-left: 0;
  }
`;

const FooterNavItem = styled.li`
  display: inline-block;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }

  a {
    color: #fff;
    font-size: 14px;
    transition: 0.15s ease-in-out;
    &:hover {
      color: ${themeGet('colors.primary', '#005182')};
    }
  }
`;

export { List, ListItem, FooterNav, FooterNavItem, Newsletter };

export default FooterWrapper;
