import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
// import { socialYoutube } from 'react-icons-kit/ionicons/socialYoutube';
import { socialLinkedin } from 'react-icons-kit/ionicons/socialLinkedin';

import Dashboard from '../../assets/image/saasTwo/Dashboard-1.32cm.png';
import Compare from '../../assets/image/saasTwo/Compare-1.32cm.png';
import Pie from '../../assets/image/saasTwo/Pie-1.32cm.png';
import Folder from '../../assets/image/saasTwo/Folder-1.32cm.png';
import Automate from '../../assets/image/saasTwo/Automate-1.32cm.png';
import Scenario from '../../assets/image/saasTwo/Scenario-1.32cm.png';
import Antwork from '../../assets/image/saasTwo/Antwork.png';
import Brandmint from 'common/src/assets/image/saasTwo/Brandmint.png';
import DNY from 'common/src/assets/image/saasTwo/DNY.png';
import GeekExpress from 'common/src/assets/image/saasTwo/GeekExpress.png';
import Pixel from 'common/src/assets/image/saasTwo/Pixel.png';
import Reef from 'common/src/assets/image/saasTwo/Reef.png';
import Telltale from 'common/src/assets/image/saasTwo/Telltale.png';
import Yakshof from 'common/src/assets/image/saasTwo/Yakshof.png';
import MEVP from 'common/src/assets/image/saasTwo/MEVP.png';
import IMCapital from 'common/src/assets/image/saasTwo/IMCapital.png';
import Seeders from 'common/src/assets/image/saasTwo/Seeders.png';
import LWAF from 'common/src/assets/image/saasTwo/LWAF.png';

import Screenshot1 from '../../assets/image/saasTwo/dash-3.png';
import Screenshot2 from '../../assets/image/saasTwo/dash-4.png';

import IpadOne from 'common/src/assets/image/saasTwo/Ipad1.png';
import IpadTwo from 'common/src/assets/image/saasTwo/Ipad2.png';
import IpadThree from 'common/src/assets/image/saasTwo/Ipad3.png';

// import AuthorOne from '../../assets/image/saasTwo/author-1.jpg';
// import AuthorTwo from '../../assets/image/saasTwo/author-2.jpg';
// import AuthorThree from '../../assets/image/saasTwo/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';

export const LANDING = '';
export const FEATURE = '/features/';
export const SOLUTIONS = '/solutions/';
export const CUSTOMERS = '/customers/';
export const PRICING = '/pricing/';
export const FAQ = '/faq/';
export const CONTACT_US = '/contact-us/';

export const date = new Date();
export const YEAR = date.getFullYear();

export const emailAddress = '@the-hq.com';

export const APPCONFIG = {
  name: 'THE HQ',
  description: 'Startup-Investor Engagement Platform',
  year: YEAR,
  emailSupport: `support${emailAddress}`,
  emailJobs: `jobs${emailAddress}`,
  mailtoSupport: `mailto:info${emailAddress}`,
  mailtoJobs: `mailto:jobs${emailAddress}`,
};

export const ACTION_BUTTONS = [
  {
    label: 'Sign In',
    path: '',
    url: `${process.env.GATSBY_HQ_APP}`,
    offset: '0',
    staticLink: true,
  },
];

export const MENU_ITEMS = [
  {
    label: 'Product',
    path: '#banner_section',
    url: LANDING,
    offset: '0',
  },
  {
    label: 'Solutions',
    path: '#solutions',
    url: SOLUTIONS,
    offset: '0',
  },
  {
    label: 'Features',
    path: '#features',
    url: FEATURE,
    offset: '0',
  },
  {
    label: 'Customers',
    path: '#clients',
    url: CUSTOMERS,
    offset: '0',
  },
  // {
  //   label: 'Pricing',
  //   path: '#pricing_section',
  //   url: PRICING,
  //   offset: '0',
  // },
  // {
  //   label: 'Testimonial',
  //   path: '#testimonial_section',
  //   offset: '0',
  // },
  // {
  //   label : 'FAQ',
  //   path  : '#faq_section',
  //   url   : FAQ,
  //   offset: '0',
  // },
  // {
  //   label: 'Contact Us',
  //   path: '#subscribe_section',
  //   url: CONTACT_US,
  //   offset: '0',
  // },
];

export const IpadCarousel = [
  {
    id: 1,
    title: 'Ipad 1',
    image: IpadOne,
  },
  {
    id: 2,
    title: 'Ipad 2',
    image: IpadTwo,
  },
  {
    id: 3,
    title: 'Ipad 3',
    image: IpadThree,
  },
];

export const StartupBenefits = [
  'Consolidated business dashboard.',
  'Improved investor relations.',
  'Increased information sharing.',
  'Standardized due diligence processes.',
  'Enhanced equity management.',
];

export const InvestorBenefits = [
  'Streamlined startup data collection.',
  'Increased data granularity and real-time info.',
  'Consolidated performance reports.',
  'Increased confidence in reporting.',
  'Higher LP satisfaction.',
];

export const QuoteText =
  'We’re here to simplify data and accessibility by drawing one global record of ownership for all stakeholders.';

export const FEATURES = [
  {
    icon: Dashboard,
    title: 'Performance Dashboard and Reporting',
    status: '',
    description:
      'Visualize, analyze and report financial, operational and KPI performances.',
  },
  {
    icon: Pie,
    title: 'Equity Management',
    status: '',
    description:
      'Deep dive into your fundraising rounds, shareholder relations and Cap Table simulations.',
  },
  {
    icon: Compare,
    title: 'Benchmarking & Comparison',
    status: '',
    description:
      'Intuitively consolidate and compare the performance and value of your portfolios/startups against industry level.',
  },
  {
    icon: Folder,
    title: 'Data Room',
    status: 'Coming Soon',
    description:
      'Create, store and share confidential documents in the context of reporting or due diligence.',
  },
  {
    icon: Scenario,
    title: 'Scenario Analysis',
    status: 'Coming Soon',
    description:
      'Eliminate the need for spreadsheets and simulate the impact of new financings, or projections.',
  },
  {
    icon: Automate,
    title: 'Report Generation',
    status: 'Coming Soon',
    description:
      'Generate ready-made automatic custom or periodic reports with any stakeholders on the go.',
  },
];

export const SCREENSHOTS = [
  {
    icon: ic_monetization_on,
    title: 'Budget Overview',
    image: Screenshot2,
  },
  {
    icon: ic_settings,
    title: 'Create & Adjust',
    image: Screenshot1,
  },
  {
    icon: pieChart,
    title: 'View Reports',
    image: Screenshot2,
  },
  {
    icon: briefcase,
    title: 'Integrations',
    image: Screenshot1,
  },
];

export const TESTIMONIAL = [
  {
    image: DNY,
    href: 'http://www.dnygroup.com',
  },

  {
    image: Reef,
    href: 'https://www.reefkinetics.com',
  },
  {
    image: MEVP,
    href: 'https://www.mevp.com',
  },
  {
    image: IMCapital,
    href: 'https://www.im-capital.com',
  },
  {
    image: GeekExpress,
    href: 'https://www.geekexpress.com',
  },
  {
    image: Seeders,
    href: 'http://seeders.co',
  },
  {
    image: LWAF,
    href: 'https://www.lwaf.co',
  },
  {
    image: Yakshof,
    href: 'https://www.yakshof.com',
  },
  {
    image: Brandmint,
    href: 'https://www.brandmint.com',
  },
  {
    image: Pixel,
    href: 'https://www.pixel38.com',
  },
  {
    image: Telltale,
    href: 'https://www.telltale.co',
  },
  // {
  //   image: AuthorOne,
  //   review:
  //
  //   name:
  //   designation:
  //   twitterProfile:
  //   organization:
  //   organizationURL:
  // },
];

export const SOCIAL_PROFILES = [
  {
    icon: socialTwitter,
    url: 'https://twitter.com/thehqlab',
  },
  {
    icon: socialFacebook,
    url: 'https://www.facebook.com/thehqlab/',
  },
  {
    icon: socialLinkedin,
    url: 'https://www.linkedin.com/company/thehqlab/',
  },
];

// export const PROCESS_ITEMS = [
//   {
//     image: Process1,
//     title: '',
//     description:
//       '',
//   },
//   {
//     image: Process2,
//     title: '',
//     description:
//       '',
//   },
//   {
//     image: Process3,
//     title: '',
//     description:
//       '',
//   },
// ];

// export const MONTHLY_PRICING_TABLE = [
//   {
//     freePlan: true,
//     name: 'Basic Account',
//     description: 'For Small teams or group who need to build website ',
//     price: '$ 0',
//     priceLabel: 'Only for first month',
//     buttonLabel: 'CREATE FREE ACCOUNT',
//     url: '#',
//     listItems: [
//       {
//         content: '12',
//       },
//       {
//         content: '1,000 Templates ',
//       },
//       {
//         content: ' Tools',
//       },
//       {
//         content: ' Store ',
//       },
//       {
//         content: 'Webmaster ',
//       },
//     ],
//   },
//   {
//     name: 'Business Account',
//     description: 'For Mediums teams or group who need to build website ',
//     price: '$ 9.99',
//     priceLabel: 'Per month & subscription yearly',
//     buttonLabel: 'START FREE TRIAL',
//     url: '#',
//     listItems: [
//       {
//         content: 'Drag & Drop Builder',
//       },
//       {
//         content: '1,000s of Templates Ready',
//       },
//       {
//         content: 'Blog Tools',
//       },
//       {
//         content: 'eCommerce Store ',
//       },
//       {
//         content: '30+ Webmaster Tools',
//       },
//     ],
//   },
//   {
//     name: 'Premium Account',
//     description: 'For Large teams or group who need to build website ',
//     price: '$ 12.99',
//     priceLabel: 'Per month & subscription yearly',
//     buttonLabel: 'START FREE TRIAL',
//     url: '#',
//     listItems: [
//       {
//         content: 'Drag & Drop Builder',
//       },
//       {
//         content: '1,000s of Templates Ready',
//       },
//       {
//         content: 'Blog Tools',
//       },
//       {
//         content: 'eCommerce Store ',
//       },
//       {
//         content: '30+ Webmaster Tools',
//       },
//     ],
//   },
// ];

// export const YEARLY_PRICING_TABLE = [
//   {
//     freePlan: true,
//     name: 'Basic Account',
//     description: 'For a single client or team who need to build website ',
//     price: '$0',
//     priceLabel: 'Only for first month',
//     buttonLabel: 'CREATE FREE ACCOUNT',
//     url: '#',
//     listItems: [
//       {
//         content: 'Drag & Drop Builder',
//       },
//       {
//         content: '1,000s of Templates Ready',
//       },
//       {
//         content: 'Blog Tools',
//       },
//       {
//         content: 'eCommerce Store ',
//       },
//       {
//         content: '30+ Webmaster Tools',
//       },
//     ],
//   },
//   {
//     name: 'Business Account',
//     description: 'For Small teams or group who need to build website ',
//     price: '$6.00',
//     priceLabel: 'Per month & subscription yearly',
//     buttonLabel: 'START FREE TRIAL',
//     url: '#',
//     listItems: [
//       {
//         content: 'Unlimited secure storage',
//       },
//       {
//         content: '2,000s of Templates Ready',
//       },
//       {
//         content: 'Blog Tools',
//       },
//       {
//         content: '24/7 phone support',
//       },
//       {
//         content: '50+ Webmaster Tools',
//       },
//     ],
//   },
//   {
//     name: 'Premium Account',
//     description: 'For Large teams or group who need to build website ',
//     price: '$9.99',
//     priceLabel: 'Per month & subscription yearly',
//     buttonLabel: 'START FREE TRIAL',
//     url: '#',
//     listItems: [
//       {
//         content: 'Drag & Drop Builder',
//       },
//       {
//         content: '3,000s of Templates Ready',
//       },
//       {
//         content: 'Advanced branding',
//       },
//       {
//         content: 'Knowledge base support',
//       },
//       {
//         content: '80+ Webmaster Tools',
//       },
//     ],
//   },
// ];

// export const FAQ_DATA = [
//   {
//     expend: true,
//     title: 'How to contact with Customer Service?',
//     description:
//       'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
//   },
//   {
//     title: 'App installation failed, how to update system information?',
//     description:
//       'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
//   },
//   {
//     title: 'Website reponse taking time, how to improve?',
//     description:
//       'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
//   },
//   {
//     title: 'New update fixed all bug and issues?',
//     description:
//       'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
//   },
// ];

// export const FOOTER_WIDGET = [
//   {
//     title: 'About Us',
//     menuItems: [
//       {
//         url: '#',
//         text: 'Support Center',
//       },
//       {
//         url: '#',
//         text: 'Customer Support',
//       },
//       {
//         url: '#',
//         text: 'About Us',
//       },
//       {
//         url: '#',
//         text: 'Copyright',
//       },
//       {
//         url: '#',
//         text: 'Popular Campaign',
//       },
//     ],
//   },
//   // {
//   //   title: 'Our Information',
//   //   menuItems: [
//   //     {
//   //       url: '#',
//   //       text: 'Return Policy',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Privacy Policy',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Terms & Conditions',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Site Map',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Store Hours',
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'My Account',
//   //   menuItems: [
//   //     {
//   //       url: '#',
//   //       text: 'Press inquiries',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Social media directories',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Images & B-roll',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Permissions',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Speaker requests',
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'Policy',
//   //   menuItems: [
//   //     {
//   //       url: '#',
//   //       text: 'Application security',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Software principles',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Unwanted software policy',
//   //     },
//   //     {
//   //       url: '#',
//   //       text: 'Responsible supply chain',
//   //     },
//   //   ],
//   // },
// ];

export const FOOTER_MENU = [
  {
    label: '',
    path: '#',
  },
  // {
  //   label: 'Privacy Policy',
  //   path: '#',
  // },
  // {
  //   label: 'Cookie Policy',
  //   path: '#',
  // },
];
