import styled from 'styled-components';
import { themeGet } from 'styled-system';

import circleBg from 'common/src/assets/image/saasTwo/dash-bg.png';

// max-height: 469px;
// min-height: 361px;

const CarouseWrapper = styled.div`
  width: calc(100%);
  background-image: url(${circleBg});
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: 1366px) {
    background-size: contain;
    align-self: center;
  }
  @media only screen and (max-width: 991px) {
    width: calc(100% - 350px);
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
    align-self: flex-start;
  }
  .glide {
    width: 337px;
    margin: 0 auto 30px;
    position: relative;
    @media only screen and (max-width: 991px) {
      width: 310px;
    }
    @media only screen and (max-width: 667px) {
      width: 260px;
    }
    .glide__slide {
      img {
        display: inline-block;
        height: 431px;
        width: 100%;
        @media (max-width: 420px) {
          height: 361px;
        }
      }
    }
    .glide__bullets {
      display: flex;
      justify-content: center;
      width: auto;
      margin-top: 10px;
      flex-direction: row;
      > button.glide__bullet {
        width: 20px;
        height: 6px;
        border-radius: 5px;
        margin-left: 5px;
        margin-right: 5px;
        background-color: #d8dce9;
        transition: height 0.3s ease;
        &.glide__bullet--active {
          width: 35px;
          background-color: ${themeGet('colors.primary')};
        }
      }
    }
  }
`;

export const TextWrapper = styled.div`
  width: 428px;
  align-self: center;
  @media only screen and (max-width: 1366px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    width: 350px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  h2 {
    color: ${themeGet('colors.headingColor', '#0F2137')};
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  p {
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
  > p {
    margin-bottom: 40px;
  }
  .feature__block {
    padding-top: 30px;
    cursor: pointer;
    transition: all 0.05s ease;
    .content__wrapper {
      margin-left: 10px;
    }
    h3 {
      color: ${themeGet('colors.headingColor', '#0F2137')};
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 9px;
      @media only screen and (max-width: 1366px) {
        line-height: 26px;
        margin-bottom: 7px;
      }
    }
  }
`;

export default CarouseWrapper;
