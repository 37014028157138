import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
// import Image from 'common/src/components/Image';
// import Container from 'common/src/components/UI/Container';
// import Button from 'common/src/components/Button';
import { OptionWrapper } from './sections.style';
// import DriverImage from 'common/src/assets/image/ride/driver-side.svg';
// import RiderImage from 'common/src/assets/image/ride/riding-share.svg';
import StartupIllustration from 'common/src/assets/image/saasTwo/startup-illustration.png';
import InvestorIllustration from 'common/src/assets/image/saasTwo/investor-illustration.png';

// import StartupIllustrationSVG from 'common/src/assets/image/saasTwo/startup-illustration.svg';
// import InvestorIllustrationSVG from 'common/src/assets/image/saasTwo/investor-illustration.svg';

import {
  StartupBenefits,
  InvestorBenefits,
} from 'common/src/data/SaasTwo/index';

import { Picture } from 'react-responsive-picture';

const BenefitList = ({ desDetails, options }) => {
  return options.map((list, index) => (
    <Text {...desDetails} content={list} key={index} />
  ));
};

const Sections = ({
  generalCol,
  row,
  col,
  desTitleWrapper,
  rideTitle,
  desOnHover,
  desDetails,
  imageWrapper,
}) => {
  const [state, setState] = useState({
    active: true,
  });
  const activeStatus = state.active;

  return (
    <Box {...generalCol} as="div" id="markets">
      <OptionWrapper id="markets">
        <Box {...row}>
          {/* Startup Block */}
          <Box
            {...col}
            className={
              activeStatus
                ? 'startupBlock active-item invert-background'
                : 'startupBlock'
            }
            onMouseEnter={() => setState({ active: true })}
          >
            <Box
              {...desTitleWrapper}
              className="desTitleWrapper desTitleWrapperLeft"
            >
              <Heading
                {...rideTitle}
                content="For Startups"
                className="desTitle"
              />
              <Box {...desOnHover} className="desOnHover desOnHoverLeft">
                <BenefitList
                  desDetails={desDetails}
                  options={StartupBenefits}
                />
              </Box>
            </Box>
            <Box className="image_area desTitle">
              <Picture
                src={StartupIllustration}
                alt="Startup Image"
                className="startup_image_area"
              />
              {/* <Image
                src={StartupIllustration}
                alt="Startup Image"
                className="startup_image_area"
              /> */}
            </Box>
          </Box>

          {/* Investor Block */}
          <Box
            {...col}
            className={
              activeStatus === false
                ? 'investorBlock active-item'
                : 'investorBlock'
            }
            onMouseEnter={() => setState({ active: false })}
          >
            <Box className="image_area desTitle">
              {/* <Image
                src={InvestorIllustration}
                className="investor_image_area"
                alt="Investor Image"
              /> */}
              <Picture
                src={InvestorIllustration}
                alt="Investor Image"
                className="investor_image_area"
              />
            </Box>
            <Box {...desTitleWrapper} className="desTitleWrapper">
              <Heading
                {...rideTitle}
                content="For Investors"
                className="desTitle"
              />
              <Box {...desOnHover} className="desOnHover">
                <BenefitList
                  desDetails={desDetails}
                  options={InvestorBenefits}
                />
              </Box>
            </Box>
          </Box>
          {/* End of Investor Block */}
        </Box>
      </OptionWrapper>
    </Box>
  );
};

Sections.propTypes = {
  secDescription: PropTypes.object, // ok
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
  desTitleWrapper: PropTypes.object,
  rideTitle: PropTypes.object,
  desOnHover: PropTypes.object,
  desDetails: PropTypes.object,
  imageWrapper: PropTypes.object,
};

Sections.defaultProps = {
  rideTitle: {
    fontSize: ['22px', '24px', '24px', '24px', '24px'],
    fontWeight: '600',
    color: '#15172C',
    lineHeight: '1.34',
    mb: '15px',
    textAlign: 'center',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  col: {
    height: '320px', // change the height of the two sections.
    width: '48%',
    bg: '#e4e4e491',
    pt: ['50px', '50px'],
    pb: ['50px', '50px'],
    flexBox: true,
  },
  desTitleWrapper: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  desOnHover: {
    textAlign: 'right',
    width: '100%',
  },
  desDetails: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#15172C',
    lineHeight: '2',
    mb: '0',
    maxWidth: '100%',
  },
  imageWrapper: {},
};

export default Sections;
