import styled from 'styled-components';
import BannerBG from 'common/src/assets/image/saasTwo/banner-texture.png';
import { themeGet } from 'styled-system';

export const BannerWrapper = styled.section`
  padding: 100px 0 0px 0;
  background-image: url(${BannerBG}), ${themeGet('colors.primaryGradient')};
  background-size: cover;
  background-position: top center;
  @media (max-width: 575px) {
    padding: 50px 0 0 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const ButtonWrapper = styled.div`
  flex-box: true;
  justify-content: center;
  margin-top: 35px;
  text-align: center;
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const DiscountLabel = styled.div`
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 11px 15px 0 5px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 30px;
  background-color: #fff;
  height: 45px;
  @media (max-width: 990px) {
    margin-top: 50px;
  }
  @media (max-width: 420px) {
    padding: 10px;
  }
  span {
    @media (max-width: 420px) {
      font-size: 12px;
    }
  }
  .discountAmount {
    padding: 9px 21px;
    border-radius: 28px;
    text-transform: uppercase;
    @media (max-width: 420px) {
      padding: 8px 16px;
      font-size: 10px;
    }
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin: 0 0.4rem 0 0;
    background: ${themeGet('colors.primary')};
  }
  .discountText {
    font-size: 13px;
    font-weight: 400;
    color: #0f2137;
    margin-left: 10px;
  }
`;
