import React, { Fragment } from 'react';
// import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import CarouseWrapper, { TextWrapper } from './appSlider.style';

import { Picture } from 'react-responsive-picture';

const AppSlider = ({ options, title, description }) => {
  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 700,
  };

  return (
    <Container>
      <TextWrapper>
        <Heading content={title} />
        <Text content={description} />
      </TextWrapper>
      <CarouseWrapper>
        <GlideCarousel
          bullets={true}
          controls={false}
          numberOfBullets={options.length}
          options={glideOptions}
          carouselSelector="appFeatureSlider"
        >
          <Fragment>
            {options.map(item => (
              <GlideSlide key={`feature-side--key${item.id}`}>
                <Picture src={item.image} alt={item.title} />
                {/* <Image src={item.image} alt={item.title} /> */}
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
      </CarouseWrapper>
    </Container>
  );
};

export default AppSlider;
