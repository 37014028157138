import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import BannerImage from 'common/src/assets/image/saasTwo/banner-image.png';
import TiltShape from '../TiltShape';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  ButtonWrapper,
} from './banner.style';

const BannerSection = ({
  sectionWrapper,
  row,
  contentWrapper,
  title,
  description,
  imageWrapper,
  fillButton,
}) => {
  return (
    <BannerWrapper {...sectionWrapper} id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <DiscountWrapper>
              <DiscountLabel>
                <Text as="span" content="update" className="discountAmount" />
                <Text
                  as="span"
                  className="discountText"
                  content="Alpha Version Out Now"
                />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading
              {...title}
              content="Unified Startup - Investor Engagement Platform"
            />
            <Text
              {...description}
              content="Data driven platform to seamlessly manage, track and simulate startup performances, equity management and investment portfolios."
            />
            <Box>
              <ButtonWrapper>
                <AnchorLink href="#subscribe">
                  <Button title="GET IN TOUCH" {...fillButton} />
                </AnchorLink>
              </ButtonWrapper>
            </Box>
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <Image src={BannerImage} alt="banner image" />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  sectionWrapper: {
    pt: '100px',
    pb: ['70px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  imageWrapper: {
    mt: '30px',
    m: '0 -37px',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    bg: 'white',
    color: 'black',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default BannerSection;
