import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';

const IntroSection = ({
  title,
  subTitle,
  secTitleWrapper,
  secTitle,
  secHeading,
}) => {
  return (
    <Box {...secTitleWrapper}>
      <Text {...secTitle} content={title} />
      <Heading {...secHeading} content={subTitle} />
    </Box>
  );
};

IntroSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secHeading: PropTypes.object,
};

IntroSection.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '70px'],
  },
  secTitle: {
    as: 'span',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '900',
    color: '#005182',
    letterSpacing: '0.15em',
    mb: '5px',
    display: 'block',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['24px', '26px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
};

export default IntroSection;
