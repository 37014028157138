import React, { useContext } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Button from 'common/src/components/Button';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import LogoImage from 'common/src/assets/image/saasTwo/hq-logo-white.png';
import LogoImageAlt from 'common/src/assets/image/saasTwo/hq-logo.png';
import { MENU_ITEMS } from 'common/src/data/SaasTwo';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import HamburgMenu from 'common/src/components/HamburgMenu';

import NavbarWrapper, { MenuArea } from './navbar.style';

const Navbar = () => {
  const navMenu = MENU_ITEMS;
  const scrollItems = [];

  navMenu.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          // href="javascript:;"
          logoSrc={LogoImage}
          title="Portfolio"
          className="main-logo"
          onClick={() => scrollTo('#banner_section')}
        />
        <Logo
          // href="javascript:;"
          logoSrc={LogoImageAlt}
          title="THE HQ"
          className="logo-alt"
          onClick={() => scrollTo('#banner_section')}
        />
        {/* end of logo */}

        <MenuArea>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}
          <a
            href={`${process.env.GATSBY_HQ_APP}`}
            target="_blank"
            offset={84}
            rel="noreferrer noopener"
          >
            <Button className="trail" title="SIGN IN" />
          </a>
        </MenuArea>

        <div id="hello" className="drawer__handler">
          {/* IF MOBILE VERSION */}
          <Drawer
            width="300px"
            placement="right"
            open={state.isOpen}
            toggleHandler={toggleHandler}
            drawerHandler={<HamburgMenu barColor="#fff" />}
          >
            {/* MOBILE MENU ITEMS */}
            <ScrollSpyMenu
              className="mobile_menu"
              menuItems={MENU_ITEMS}
              drawerClose={true}
              offset={-100}
            />
            <div>
              <a
                href={`${process.env.GATSBY_HQ_APP}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button title="SIGN IN" style={{ width: '100%' }} />
              </a>
            </div>
          </Drawer>
        </div>
        {/* end of mobile menu */}
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
