import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';

import Container from 'common/src/components/UI/Container';
import { APPCONFIG, SOCIAL_PROFILES, YEAR } from 'common/src/data/SaasTwo';

import SocialProfile from '../SocialProfile';
import FooterWrapper, { Newsletter } from './footer.style';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const FormBuilder = ({ contactRow, contactButton }) => {
  // const val = 'g-recaptcha-response';
  const [info, setInfo] = useState('initial value');
  const [captcha, isCaptcha] = useState(false);
  const recaptchaRef = useRef();

  // const handleAttachment = e => {
  //   this.setState({ [e.target.name]: e.target.files[0] });
  // };

  const handleRecaptcha = value => {
    setInfo(value);
    isCaptcha(true);
  };

  return (
    <form
      data-netlify-honeypot="bot-field"
      data-netlify-recaptcha="true"
      data-netlify="true"
      method="post"
      action="#"
      name="Contact Us"
      className="contactForm"
    >
      <Box {...contactRow}>
        <input type="hidden" name="form-name" value="Contact Us" />
        <Newsletter>
          <input required type="text" name="name" placeholder="Name" />
        </Newsletter>
        <Newsletter>
          <input
            required
            type="email"
            name="email"
            placeholder="Email Address"
          />
        </Newsletter>
      </Box>
      <Newsletter>
        <input required type="text" name="Subject" placeholder="Subject" />
      </Newsletter>
      <Newsletter style={{ height: 'auto', backgroundColor: 'tranparent' }}>
        <textarea
          required
          name="Message"
          placeholder="Tell us more about your business, what are your needs?"
        />
      </Newsletter>
      {/* <Newsletter style={{ backgroundColor: 'tranparent' }}>
        <input
          style={{ color: 'white' }}
          type="file"
          name="Attachment"
          // onChange={this.handleAttachment}
        />
      </Newsletter> */}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        onChange={handleRecaptcha}
      />

      <Button
        type="submit"
        disabled={captcha === false ? true : false}
        title="Submit"
        {...contactButton}
      />
    </form>
  );
};

FormBuilder.propTypes = {
  contactRow: PropTypes.object,
  contactButton: PropTypes.object,
};
FormBuilder.defaultProps = {
  contactRow: {
    flexBox: true,
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  contactButton: {
    type: 'invertButton',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'invertheaderWithBg',
    minHeight: 'auto',
  },
};

const InfoSection = ({ socialProfileStyle, contactTitleStyle }) => {
  return (
    <>
      <Box>
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // margin        :
          }}
        >
          For support or any question email us at &nbsp;
          <a
            href={APPCONFIG.mailtoSupport}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              style={{
                color: 'white',
                margin: '2px 0px 0px',
                textDecoration: 'underline',
                fontWeight: 600,
              }}
            >
              {APPCONFIG.emailSupport}
            </span>
          </a>
        </p>
      </Box>
      <Box {...socialProfileStyle}>
        <Heading content="Follow Us" {...contactTitleStyle} />
        <SocialProfile
          className="footer_social"
          items={SOCIAL_PROFILES}
          iconSize={18}
        />
      </Box>
    </>
  );
};
InfoSection.defaultProps = {
  socialProfileStyle: PropTypes.object,
};
InfoSection.defaultProps = {
  socialProfileStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const CopyrightFooter = ({ copyrightStyle, col }) => {
  return (
    <>
      <Box {...col}>
        <Text
          as="span"
          content={`©2019 - ${YEAR} The HQ`}
          {...copyrightStyle}
        />
        {/* <Icon icon={heart} size={14} className="heart_sign" /> */}
      </Box>
      {/* <Box {...col} {...flexBox}>
        <FooterNav>
          {FOOTER_MENU.map((item, index) => (
            <FooterNavItem key={`footer-nav-item-${index}`}>
              <Link href={item.path || '#'}>
                <a>{item.label}</a>
              </Link>
            </FooterNavItem>
          ))}
        </FooterNav>
      </Box> */}
      <Box {...col}>
        <a
          href="http://www.dnygroup.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          {' '}
          A DNY Group Venture
        </a>
        {/* <Icon icon={heart} size={14} className="heart_sign" /> */}
      </Box>
    </>
  );
};
CopyrightFooter.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  copyrightStyle: PropTypes.object,
};
CopyrightFooter.defaultProps = {
  col: {
    pb: '20px',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  // flexBox: {
  //   flexBox: true,
  //   justifyContent: 'space-between',
  //   // flexWrap: 'wrap'
  // },
};

const Footer = ({ row, colOne, contactTitleStyle, colTwo, noMargin }) => {
  return (
    <FooterWrapper id="subscribe">
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Heading as="h3" content="Get In Touch" {...contactTitleStyle} />
            <FormBuilder />
          </Box>

          <Box {...colTwo}>
            <InfoSection contactTitleStyle={contactTitleStyle} />
          </Box>
        </Box>
        {/* End of First Footer */}

        <Box {...row} {...noMargin} className="copyrightClass">
          <CopyrightFooter />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  colOne: PropTypes.object,
  contactTitleStyle: PropTypes.object,
  colTwo: PropTypes.object,
  noMargin: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '20px', '30px'],
  },
  colOne: {
    width: ['100%', '65%', '65%', '74%'],
    mt: 0,
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', '15px'],
  },
  contactTitleStyle: {
    color: '#fff',
    fontSize: ['16px', '18px'],
    fontWeight: '700',
    mb: ['20px', '25px'],
  },
  colTwo: {
    width: [1, '35%', '35%', '23%'],
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    mb: ['20px', 0],
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  noMargin: {
    mb: '0',
    borderTop: '1px solid',
    borderColor: 'rgba(255,255,255,0.23)',
    pt: '35px',
  },
};

export default Footer;
