import React, { useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/Container';
import NewsletterWrapper, {
  ContactFormWrapper,
} from './newsletterSection.style';

const NewsletterSection = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
  errorBox,
}) => {
  const [state, setState] = useState({
    email: '',
    status: '',
    msg: '',
  });

  // Update state each time user edits their email address
  const _handleEmailChange = event => {
    setState({
      ...state,
      email: event,
    });
  };

  // Post to MC server & handle its response
  const _postEmailToMailchimp = email => {
    addToMailchimp(email)
      .then(result => {
        if (result.result !== 'success') {
          if (result.msg.includes('is already subscribed')) {
            setState({
              status: `Already Subscribed`,
              msg: `${state.email} is already subscribed`,
            });
            setTimeout(() => {
              setState({ status: '', msg: '' });
            }, 5000);
          } else {
            setState({
              status: `error`,
              msg: result.msg,
            });
            setTimeout(() => {
              setState({ status: '', msg: '' });
            }, 5000);
          }
        } else {
          // Email address succesfully subcribed to Mailchimp
          setState({
            status: `success`,
            msg: result.msg,
          });
          setTimeout(() => {
            setState({ status: '', msg: '' });
          }, 5000);
        }
      })
      .catch(err => {
        // Network failures, timeouts, etc
        setState({
          status: 'error',
          msg: JSON.stringify(err),
        });
        setTimeout(() => {
          setState({ status: '', msg: '' });
        }, 5000);
      });
  };

  const _handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      status: 'sending',
      msg: null,
    });
    // setState callback (subscribe email to MC)
    _postEmailToMailchimp(state.email);
  };

  return (
    <Box {...sectionWrapper} as="section" id="newsletter">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading content="Subscribe to our newsletter" {...title} />
            <Text
              content="Subscribe to our newsletter and stay up-to-date with our product offerings, lastest features and updates."
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
            {state.status === `success` ? (
              <Text content="Thank you for subscribing!" {...description} />
            ) : (
              <Box>
                <ContactFormWrapper>
                  <Input
                    inputType="email"
                    name="Email"
                    value="string"
                    label="Email address"
                    iconPosition="right"
                    isMaterial={true}
                    className="email_input"
                    onChange={_handleEmailChange}
                  />
                  <Button
                    {...buttonStyle}
                    type="submit"
                    title="Subscribe"
                    onClick={_handleFormSubmit}
                  />
                </ContactFormWrapper>
                {state.status === `error` && (
                  <Box {...errorBox}>
                    <Text content={state.msg} {...description} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
};

NewsletterSection.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  errorBox: PropTypes.object,
};

NewsletterSection.defaultProps = {
  sectionWrapper: {
    pt: '70px',
    pb: '80px',
  },
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    maxWidth: ['100%', '400px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
    width: ['100%', 'auto'],
  },
  buttonStyle: {
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
  },
  errorBox: {
    backgroundColor: '#FFD54F',
    pt: '5px',
    pb: '10px',
  },
};

export default NewsletterSection;
