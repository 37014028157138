import styled from 'styled-components';
// import BgImage from 'common/src/assets/image/saasTwo/dash-bg.png';

const InfoSectionWrapper = styled.section`
  padding: 380px 0 250px 0;
  @media (max-width: 1600px) {
    padding: 240px 0 0 0;
  }
  @media (max-width: 1400px) {
    padding: 150px 0 0 0;
  }
  @media (max-width: 1300px) {
    padding: 97px 0 0 0;
  }
  @media (max-width: 1199px) {
    padding: 65px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 35px 0 0 0;
  }
  .title-container {
    @media (min-width: 768px) {
      position: absolute;
      top: 0%;
      left: 3%;
    }
  }
  .info-sec-container {
    @media (min-width: 768px) {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    @media (max-width: 768px) and (min-width: 768px) {
      top: 46%;
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
      padding-top: 40px;
    }
  }
  .section_are {
    @media (min-width: 575px) {
      margin-top: 46px;
    }
  }
`;

export const TextWrapper = styled.section`
  .text {
    font-weight: normal;
    font-style: italic;
    color: #5d646d;
    line-height: 2;
    text-align: center;
  }
`;
// .image_area {
//   background-image: url(${BgImage});
//   background-position: bottom left;
//   background-size: contain;
//   background-repeat: no-repeat;
//   padding: 0 0 0 40px;
//   @media (max-width: 767px) {
//     padding-top: 0;
//   }
// }

export default InfoSectionWrapper;
