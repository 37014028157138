import React from 'react';
import PropTypes from 'prop-types';
// import { Icon } from 'react-icons-kit';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import IntroSection from 'common/src/components/IntroSection';

import { PrevButton, NextButton } from '../../SaasTwo/sassTwo.style.js';
import {
  TestimonialWrapper,
  TestimonialItem,
  // TestimonialHead,
  // TestimonialThumb,
} from './testimonial.style';
import { TESTIMONIAL } from 'common/src/data/SaasTwo/index';

const TestimonialSection = ({
  sectionWrapper,
  reviewStyle,
  nameStyle,
  designationStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    hoverpause: true,
    autoplay: 6000,
    perView: 4,
    gap: 28,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 3,
      },
      767: {
        perView: 2,
      },
      500: {
        perView: 2,
      },
    },
  };

  return (
    <Box {...sectionWrapper} as="section" id="clients">
      <Container noGutter mobileGutter width="1200px">
        <IntroSection
          title="Released An Alpha Version"
          subTitle="Used by several startups &amp; investors"
        />

        <TestimonialWrapper>
          <GlideCarousel
            carouselSelector="testimonial-carousel"
            options={carouselOptions}
            prevButton={
              <PrevButton>
                <span />
              </PrevButton>
            }
            nextButton={
              <NextButton>
                <span />
              </NextButton>
            }
          >
            <>
              {TESTIMONIAL.map((item, index) => (
                <GlideSlide
                  key={`testimonial-item-${index}`}
                  style={{ width: '279px' }}
                >
                  <TestimonialItem>
                    {/* <TestimonialThumb> */}
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Image
                        src={item.image}
                        alt={`testimonial-avatar-${index + 1}`}
                      />
                    </a>
                    {/* </TestimonialThumb> */}
                    {/* <TestimonialHead>
                      <TestimonialThumb>
                        <Image
                          src={item.image}
                          alt={`testimonial-avatar-${index + 1}`}
                        />
                      </TestimonialThumb>
                      {/* <Link href={item.twitterProfile || '#'}>
                        <a>
                          <Icon icon={twitter} size={24} />
                        </a>
                      </Link>
                    </TestimonialHead>
                    <Text {...reviewStyle} content={item.review} />
                    <Heading as="h3" content={item.name} {...nameStyle} />
                    <Text
                      as="span"
                      content={item.designation}
                      {...designationStyle}
                    />
                    <Link href={item.organizationURL || '#'}>
                      <a className="reviewer_org">{item.organization}</a>
                    </Link> */}
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  reviewStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['40px', '40px', '40px', '40px'],
    pb: ['40px', '40px', '40px', '40px'],
  },
  // reviewStyle: {
  //   fontSize: '16px',
  //   color: '#43414e',
  //   lineHeight: '1.5',
  //   mb: '30px',
  // },
  // nameStyle: {
  //   fontSize: '16px',
  //   color: '#302b4e',
  //   fontWeight: '600',
  //   mb: '7px',
  // },
  // designationStyle: {
  //   fontSize: '14px',
  //   color: '#43414e',
  //   mb: '0',
  // },
};

export default TestimonialSection;
