import styled from 'styled-components';
import { themeGet } from 'styled-system';

const OptionWrapper = styled.section`
  .image_area {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .startup_image_area,
  .investor_image_area {
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 20%;
    @media (max-width: 550px) {
      height: 49px;
    }
  }
  .desTitleWrapper {
    transition: all 0.5s;
    justify-content: center;
    @media (max-width: 550px) {
      width: 100%;
      justify-content: end;
    }
    .desTitle {
      position: relative;
      color: ${themeGet('colors.primary')};
      transition: 0.35s ease-in-out;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 4px);
        height: 9px;
        bottom: 7px;
        left: -4px;
        z-index: -1;
        transform-origin: right center 0;
        transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
    }

    .desOnHover {
      text-align: right;
    }
    .buttonStyle {
      .reusecore__button {
        background-color: transparent;
        > span {
          background-color: transparent;
          padding: 0;
          position: relative;
          @media (max-width: 700px) {
            font-size: 14px;
          }
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: #15172c;
            bottom: 1px;
            left: 0px;
            z-index: -1;
            transform-origin: right center 0;
            transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          }
        }
      }
      &.signupBtn {
        .reusecore__button {
          margin-top: 75px;
          @media (max-width: 768px) {
            margin-top: 45px;
          }
          > span {
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
  .desTitleWrapperLeft {
    align-items: flex-start;
    .desOnHoverLeft {
      text-align: left;
    }
  }
  .startupBlock,
  .investorBlock {
    width: 100%;
    height: 100px;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.5s ease;
    overflow: hidden;
    .desTitleWrapper {
      transform: translateY(25%);
      transition: all 0.5s;
    }

    @media (max-width: 550px) {
      padding: 15px;
    }
    @media (max-width: 480px) {
      background: #f5f5f5;
      margin-bottom: 15px;
      margin-left: 0 !important;
      padding: 20px;
      border-radius: 5px;
    }
    .desOnHover {
      cursor: pointer;
      display: none;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      @media (max-width: 480px) {
        flex-direction: column;
        background: transparent;
      }
    }
    &.active-item {
      height: 280px;
      padding-top: 50px;
      padding-bottom: 50px;
      background: ${themeGet('colors.primaryGradientLight')};
      &.invert-background {
        background: ${themeGet('colors.primaryGradientLightInverted')};
      }
      .desTitleWrapper {
        transform: translateY(0%);
        justify-content: center;
      }
      @media (max-width: 480px) {
        display: flex;
        flex-direction: row;
      }
      .desOnHover {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
      }
      .image_area {
        width: 30%;
        align-items: center;
        justify-content: center;
      }
      .desTitleWrapper {
        width: 70%;
      }
      .startup_image_area,
      .investor_image_area {
        max-width: 90%;
        height: auto;
      }
      .investorBlock {
        padding-left: 30px;
      }
    }
  }
  .startupBlock {
    padding: 10px 15px 10px 30px;
    @media (max-width: 480px) {
      margin-top: 15px;
    }
  }
  .investorBlock {
    padding: 10px 30px 10px 15px;
    margin-top: 15px;
    @media (max-width: 480px) {
      width: 100%;
      padding-left: 30px;
    }
  }
`;

export { OptionWrapper };

// .desDetailsFirst {
//   margin-top: 65px;
//   line-height: 32px;
//   @media (max-width: 768px) {
//     line-height: 22px;
//     margin-top: 30px;
//   }
// }
