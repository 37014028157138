import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
// import { Modal } from '@redq/reuse-modal';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasTwoTheme } from 'common/src/theme/saasTwo';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  SaasTwoWrapper,
  ContentWrapper,
} from '../containers/SaasTwo/sassTwo.style';

import BannerSection from '../containers/SaasTwo/Banner';
import Navbar from '../containers/SaasTwo/Navbar';
import InfoSection from '../containers/SaasTwo/Info/index';
import NewsletterSection from '../containers/SaasTwo/NewsletterSection';
import FeatureSection from '../containers/SaasTwo/Feature';
import TestimonialSection from '../containers/SaasTwo/Testimonial';
import Footer from '../containers/SaasTwo/Footer';
import SEO from '../components/seo';

// import WorkingProcessSection from '../containers/SaasTwo/WorkingProcess';
// import PricingSection from '../containers/SaasTwo/Pricing';
// import PartnerSection from '../containers/SaasTwo/Partner';
// import FaqSection from '../containers/SaasTwo/Faq';
// import TrialSection from '../containers/SaasTwo/Trial';
// import UpdateScreen from '../containers/SaasTwo/UpdateScreen';

export default () => {
  return (
    <ThemeProvider theme={saasTwoTheme}>
      <Fragment>
        <SEO />
        {/* <Modal /> */}
        <ResetCSS />
        <GlobalStyle />
        {/* End of SaaS head section */}
        {/* Start SaaS wrapper section */}

        <SaasTwoWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <ContentWrapper>
            <BannerSection />
            <InfoSection />
            <FeatureSection />
            <TestimonialSection />
            <NewsletterSection />
            {/* <WorkingProcessSection /> */}
            {/* <UpdateScreen /> */}
            {/* <PricingSection /> */}
            {/* <PartnerSection /> */}
            {/* <FaqSection /> */}
            {/* <TrialSection /> */}
          </ContentWrapper>

          <Footer />
        </SaasTwoWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
